module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@4.15.0_babel-plugin-styled-components@1.13.3_styled-component_luufwuwfk4qt74dkagdsvpbvd4/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-background-image@1.6.0_gatsby@3.15.0_@types+node@22.5.2_@types+webpack@4.41.39_babel-e_jm7zpye3cagxq6r44sdpvgisna/node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-image@1.15.0_@babel+core@7.25.2_gatsby-plugin-sharp@3.15.0_gatsby@3.15.0_@types_f3clyx3fnltdxdnohwmcqm3tyq/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@4.15.0_gatsby@3.15.0_@types+node@22.5.2_@types+webpack@4.41.39_babel-es_mkwfu5bijrblkecbv7ctynfude/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-scroll-reveal@0.0.7_gatsby@3.15.0_@types+node@22.5.2_@types+webpack@4.41.39_bab_6xcmpjkfpfcqubay2rxgy6ncmy/node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.1,"once":true,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 50%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@3.15.0_gatsby@3.15.0_@types+node@22.5.2_@types+webpack@4.41.3_gq2ntdqpreb3qm2y47n5peixv4/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MRB7VKR","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
